
import {
    BrowserRouter as Router,
    useParams,
} from "react-router-dom";
  
import TextEditor from './TextEditor';
import Submitted from './Submitted';
import logo from "../../assets/images/notepad/logo.png";
import { baseURL } from "../../utilities/constant";
import drawOnCanvas from "../../utilities/drawOnCanvas";
// import getEventFromUrl from "../../utilities/getEventFromUrl";

import axios from "axios";

const DrawingNavBar = () => {
    
  const { eventUrl } = useParams();
  let eventId = eventUrl===undefined?"":eventUrl;

    const onUndo=()=>{
        let linesArray=window.canvasData.split("|");
        if(linesArray.length>3){
            let linesNewArray=[];
            if(linesArray.slice(-1)[0]===""){
                linesArray.pop();
            }
            linesArray.pop();
            for(let iLine=0; iLine<linesArray.length; iLine++){ // 0 and 1 are for text
                linesNewArray.push(linesArray[iLine]);
            }
            window.canvasData=linesNewArray.join("|")+"|";
            drawOnCanvas(document.getElementById("drawCanvas"));
        }
    }

    const onClear=()=>{
        if (window.confirm("Do you want to clear the canvas?")) {
            const canvas = document.getElementById("drawCanvas");
            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            window.canvasData="|,,,|";
        }
    }

    const onText=()=>{
        const textEditor = document.getElementById("TextEditor");
        textEditor.style.display="block";
    }
/*
    const onTest=()=>{
        console.log("***************** ON TEST *********************");
        const canvas = document.getElementById("drawCanvas")
        drawOnCanvas(canvas);
        console.log("*** window.canvasData vs canvas.toDataURL() ***"); 
        console.log("window.canvasData="+window.canvasData);
        console.log("canvas.toDataURL()="+canvas.toDataURL());
        console.log("***              Get Event                  ***"); 
        //console.log("getEventFromUrl()="+getEventFromUrl());
        console.log("eventId="+eventId);
        console.log("***********************************************");
    }
*/
    const addTile = (data) => {
        const testArray=data.split("|");
        if(data.length<35&&testArray[0].length<3){
            alert("There is not enough content to submit.");
        } else {
            //let myEvent = getEventFromUrl();
            axios.post(`${baseURL}/save`, { event:eventId, tile: data }).then((res) => {
                const Success = document.getElementById("Success");
                Success.style.display="block";
                console.log("saved:"+res.data);

            }).catch(function (error) {
                console.log(error.toJSON());
                // addTile(data);
                alert("Please try to submit again.");
                const Submitted = document.getElementById("Submitted");
                Submitted.style.display="none";
            });
        }
    };

    const onSave=()=>{
        const Submitted = document.getElementById("Submitted");
        Submitted.style.display="block";
        // ** comparing sending pngs to sending raw data in an array
        // ** const canvas = document.getElementById("drawCanvas");
        // ** const imagedata = canvas.toDataURL("image/png");
        // ** console.log("imagedata="+imagedata);
        // ** console.log("window.canvasData="+window.canvasData);
        addTile(window.canvasData);
    }
    return (
        <>
        <TextEditor />
        <Submitted />
        <div className="flex scale-x">
            <div className="flex-auto" style={{paddingTop:"15px"}}>
            <img src={logo} alt="Logo"/>
            </div>
        </div>
        <div className="flex scale-x" style={{paddingBottom:"5px"}}>
            <div className="flex-auto">

                {/*<div className="square-button float-right cancel-btn"></div>*/}
            
                <div className="square-button float-right save-btn"
                    onMouseUp={() => onSave()}
                    onTouchEnd={() => onSave()}
                ></div>
                <div className="square-button float-right">
&nbsp;
                </div>
                <div className="square-button bg-abt-blue-dark float-right clear-btn"
                    onMouseUp={() => onClear()}
                    onTouchEnd={() => onClear()}
                ></div>
                <div className="square-button bg-abt-blue-dark float-right undo-btn"
                    onMouseUp={() => onUndo()}
                    onTouchEnd={() => onUndo()}
                ></div>
                <div className="square-button float-right text-btn"
                    onMouseUp={() => onText()}
                    onTouchEnd={() => onText()}
                ></div>
            </div>
        </div>
        </>
    );
}
export default DrawingNavBar;