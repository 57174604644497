export default function scaleCanvas() {
    const canvas = document.getElementById("drawCanvas");
    var width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
    var height = (window.innerHeight > 0) ? window.innerHeight : window.screen.height;
    width=width*.8;

    if(canvas!=null){
        let ratioWidth=((width-Number(canvas.offsetLeft))-30)/window.canvasSize;
        let ratioHeight=((height-Number(canvas.offsetTop))-15)/window.canvasSize;
        if(window.canvasSize*ratioHeight>(width-Number(canvas.offsetLeft))){
            window.ratio=ratioWidth;
        } else {
            window.ratio=ratioHeight;
        }
        let mySize=window.canvasSize*window.ratio;
        canvas.style.width=mySize+"px";
        canvas.style.height=mySize+"px";
        const collection = document.getElementsByClassName("scale-x");
        for(let i=0; i<collection.length; i++){
            collection[i].style.width=mySize+"px";
        }

    }
};