import React, { useEffect } from 'react';

import "./NotepadPage.css";

import Canvas from './Canvas';
import DrawingNavBar from './DrawingNavBar';
import scaleCanvas from '../../utilities/scaleCanvas';


// To prevent it from being removed

function NotepadPage() {
  useEffect(() => {
    const handleWindowResize = () => {
      scaleCanvas();
    };
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });
  return (
    <>
      <div className="flex w-screen h-screen bg-abbott-black justify-center overflow-hidden touch-none">
        <div className="">
          <DrawingNavBar />
          <div className="justify-center">
            <Canvas />
          </div>
        </div>
      </div>
    </>
  );
}
export default NotepadPage;